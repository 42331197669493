
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "ffd7a82c-dffd-4a51-9e8f-c7237a22c7d1"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/webapp/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
